<template>
  <div>
    <base-nav-list>
      <base-menu-item
        v-for="(record, index) in computedRecords"
        :key="record.id"
        :to="createChildRecordTargetRoute(record)"
        :margin-size="marginSize"
        @dragstart="startDrag($event, record)"
        @dragend="endDrag"
        @dragover="dragOver($event, index, record)"
        @drop="onDrop($event, record)"
        @contextmenu="openContextMenu($event, record)"
        @touchstart="handleTouchStart($event, record)"
        @touchend="handleTouchEnd"
        :class="getDropClass(index)"
      >
        <template v-slot:icon>
          <!-- Expand Record btn-icon -->
          <expand-record-button
            v-if="isExpandableRecord(record)"
            :record="record"
            :disabled="!canNavigateToRecord(record.id)"
            :to="createRecordTargetRoute(record.id)"
          ></expand-record-button>

          <!-- record icon -->
          <record-badge-button
            :record="record"
            :to="createChildRecordTargetRoute(record)"
          >
            <template v-slot:tooltip>
              {{ tooltipSelectedRecord(record) }}
            </template>
          </record-badge-button>
        </template>

        <template v-slot:tooltipContent>
          <div>
            {{ getStateDescription(record) }}
          </div>
        </template>

        <!-- Record Name -->
        {{ displayRecordName(record) }}
      </base-menu-item>
    </base-nav-list>

    <context-menu
      ref="menu"
      :selected-record="selectedRecord"
      :moved-record="movedRecord"
      :moved-records="movedRecords"
      :records="records"
      @onContextMenuAction="onContextMenuAction"
    >
    </context-menu>
  </div>
</template>

<script>
// model
import {
  contextMenuRecordOperation,
  findRecordType,
  recordType
} from "@/model/record/recordModel";
import { eventNames } from "@/model/common/events/eventConst";

// design
import { size } from "@/design/spacing/Spacing";
import { iconPasteShortcut } from "@/design/icon/iconConst";

// services
import {
  fullRecordName,
  isRecordExpandable
} from "@/services/record/recordService";
import { isMobile } from "@/services/dom/windowService";

// plugins
import { createFontAwesomeSolidIcon, fasIconName } from "@/plugins/fontawesome";

// Mixin
import { dragDropRecordMixin } from "@/mixins/shared/base/dragDrop/dragDropRecordMixin";
import { badgeableRecordMixin } from "@/mixins/shared/record/badgeableRecordMixin";
import { isAllowedAuthor } from "@/services/config/configService";

export default {
  name: "RecordList",
  mixins: [dragDropRecordMixin, badgeableRecordMixin],
  components: {
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    ExpandRecordButton: () =>
      import("@/components/shared/core/buttons/ExpandRecordButton"),
    RecordBadgeButton: () => import("@/components/record/RecordBadgeButton"),
    ContextMenu: () => import("@/components/shared/core/menu/ContextMenu")
  },
  data() {
    return {
      selectedRecord: undefined,
      iconShortcut: iconPasteShortcut,
      fontAwesomeSolidIcon: createFontAwesomeSolidIcon,
      fasIcon: fasIconName,
      longHoldTimer: null,
      isTouchEvent: false
    };
  },
  props: {
    /**
     * records
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    records: undefined,

    /**
     * Create Child Record Target Route Function
     * @param {Number} id record id
     * @return {{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}} Child Record Target Route
     */
    createChildRecordTargetRoute: {
      type: Function,
      required: true
    },

    /**
     * can Navigate To Record Function
     * @param {Number} id record id
     * @return {Boolean|boolean} true if navigatable To Record by provided record id
     */
    canNavigateToRecord: {
      type: Function,
      required: true
    },

    /**
     * create Record Target Route
     * @param {Number} id record id
     * @return {{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}} Record Target Route
     */
    createRecordTargetRoute: {
      type: Function,
      required: true
    },

    movedRecord: undefined,

    movedRecords: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    computedRecords() {
      return this.records ?? [];
    },

    /**
     * Margin Size for Drawer List items
     * @return {string}
     */
    marginSize() {
      return size?.size4 ?? "0";
    },

    /**
     * is Allowed Author (still under development), so it is available for development only
     * @return {boolean}
     */
    isAllowedAuthor() {
      return isAllowedAuthor();
    }
  },
  methods: {
    /**
     * tooltip Selected Record
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {string}
     */
    tooltipSelectedRecord(record) {
      if (record?.isLink ?? false)
        return `Shortcut to  ${fullRecordName(record)}`;

      const type = findRecordType(record?.recordTypeId ?? recordType.RECORD)
        ?.name;

      return `Select ${type}: ${fullRecordName(record)}`;
    },

    /**
     * Get State Description
     * @param record
     * @return {string}
     */
    getStateDescription(record) {
      return record?.stateDescription ?? "";
    },

    /**
     * Verify if record is Shortcut
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {*|boolean}
     */
    isRecordShortcut(record) {
      return record?.isLink ?? false;
    },

    /**
     * display Record Name
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {string}
     */
    displayRecordName(record) {
      return fullRecordName(record);
    },

    /**
     * is Expandable Record
     * @param {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {boolean} true if provided record is expandable
     */
    isExpandableRecord(record) {
      return isRecordExpandable(record);
    },

    /**
     * Context Menu Open Event
     * @param event
     * @param record
     */
    openContextMenu(event, record) {
      try {
        console.log(
          `${this.$options.name}  openContextMenu() isMobileDevice`,
          isMobile()
        );

        if (!this.isAllowedAuthor) return false;

        if (!isMobile()) {
          // Save right-clicked record data
          this.selectedRecord = record;
          event.preventDefault();
          this.$refs.menu.open(event);
        } else {
          event.preventDefault();
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Handle Touch Start Event
     * Specifically for Touch Devices
     * @param event
     * @param record
     */
    handleTouchStart(event, record) {
      if (!this.isAllowedAuthor) return false;

      if (event.touches.length === 1) {
        // start long hold timer
        this.longHoldTimer = setTimeout(() => {
          console.log(`${this.$options.name}  handleTouchStart() recordHolded`);
          event.preventDefault();
          this.selectedRecord = record;
          this.$refs.menu.openTouch(event);
        }, 700);
      }
    },

    /**
     * Handle Touch End Event
     * Specifically for Touch Devices
     */
    handleTouchEnd() {
      // clear the timer
      clearTimeout(this.longHoldTimer);
    },

    /**
     * Context Menu Command Action
     * @param {String|string} action
     * @return {Promise<Route>}
     */
    onContextMenuAction(action) {
      try {
        console.log(
          `${this.$options.name} onContextMenuAction() action`,
          action
        );
        // Jump to Record if select Command Clicked
        if (action === contextMenuRecordOperation.Select)
          return this.$router.push(
            this.createChildRecordTargetRoute(this.selectedRecord)
          );

        const payload = {
          name: action,
          record: this.selectedRecord
        };
        this.$emit(eventNames.performContextMenuAction, payload);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped>
.hover-top {
  border-top: 2px solid #3f51b5;
  background-color: #e8eaf6;
}

.hover-inside {
  border: 2px dashed #3f51b5;
  background-color: #c5cae9;
}

.hover-bottom {
  border-bottom: 2px solid #3f51b5;
  background-color: #e8eaf6;
}
</style>
