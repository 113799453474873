import {
  recordOperation,
  recordType,
  treeInsertOptions
} from "@/model/record/recordModel";
import { eventNames } from "@/model/common/events/eventConst";
import {
  isAncestorMeeting,
  isBackupMaterial,
  isCompoundFile,
  isValidOperation
} from "@/services/record/recordService";
import { extensionName } from "@/model/record/fileModel";
import { canCreateShortcut } from "@/services/api/apiContent";

export const dragDropRecordMixin = {
  data() {
    return {
      draggedRecord: undefined,
      destinationRecord: undefined,
      move: "move",
      file: "file",
      draggedFile: undefined,
      droppedRecord: undefined,
      canMoveShortcut: false,
      dropIndex: null,
      dropPosition: null, // 'top' or 'inside'
      insertOptions: treeInsertOptions
    };
  },
  computed: {},
  methods: {
    /**
     * Event initiated when record is dragged
     * @param event
     * @param record
     */
    startDrag(event, record) {
      try {
        //reset Record dragged and dropped
        this.resetVariables();
        this.draggedRecord = record;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event initiated when dragged is ended
     */
    endDrag() {
      try {
        //reset Record dragged and dropped
        this.resetVariables();
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event initiated when dragged record is hovered over other records
     * @param event
     * @param index
     * @param record
     */
    dragOver(event, index, record) {
      try {
        //this check is used for local file drop
        // in this case startDrag() is not called and dragged record is not set
        if (!this.draggedRecord) {
          if (this.isAllowedLocalFileDrop(record)) {
            event.dataTransfer.dropEffect = this.move;
            event.preventDefault();
          } else {
            event.dataTransfer.dropEffect = "none";
            event.preventDefault();
          }
          return;
        }

        // this method verifies if drop is allowed based on record type
        if (
          this.isAllowedDrop(this.draggedRecord, record, index) &&
          isValidOperation(this.draggedRecord, recordOperation.Move)
        )
          event.preventDefault();
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Class for On Dropped Record
     * @param index
     * @return {{"hover-inside": boolean, "hover-top": boolean, "hover-bottom": boolean}}
     */
    getDropClass(index) {
      return {
        "hover-top":
          this.dropIndex === index &&
          this.dropPosition === this.insertOptions.beforeSibling,
        "hover-inside":
          this.dropIndex === index &&
          this.dropPosition === this.insertOptions.lastChild,
        "hover-bottom":
          this.dropIndex === index &&
          this.dropPosition === this.insertOptions.afterSibling
      };
    },

    /**
     * Event initiated when dragged record is dropped on other record
     * @param event
     * @param destinationRecord
     */
    onDrop(event, destinationRecord) {
      try {
        //this check is used for local file drop
        // in this case startDrag() is not called and dragged record is not set
        if (!this.draggedRecord) {
          this.draggedFile = this.dropLocalFileHandler(event);
          const payload = {
            draggedFile: this.draggedFile,
            destinationRecord: destinationRecord
          };
          this.$emit(eventNames.onFileDropped, payload);
          return;
        }

        this.destinationRecord = destinationRecord;

        const payload = {
          draggedRecord: this.draggedRecord,
          destinationRecord: this.destinationRecord,
          insertPosition: this.dropPosition
        };
        //Emit Event to parent with payload
        this.$emit(eventNames.onRecordMoved, payload);
        this.draggedRecord = undefined;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Handle Local File Drop
     * @param ev drop-event
     * @return {File|file} file
     */
    dropLocalFileHandler(ev) {
      try {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        let file = undefined;
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          [...ev.dataTransfer.items].forEach((item, i) => {
            // If dropped items aren't files, reject them
            file = this.isFile(item.getAsFile());
            console.log(
              `${this.$options.name}  dropLocalFileHandler() file[${i}].name: `,
              file
            );
          });
          return file;
        } else {
          // Use DataTransfer interface to access the file(s)
          [...ev.dataTransfer.files].forEach((item, i) => {
            file = this.isFile(item.getAsFile());
            console.log(
              `${this.$options.name}  dropLocalFileHandler() file[${i}].name: `,
              file
            );
          });
          return file;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Verifies if dragged record can be dropped on another record
     * @param dragRecord - Record that is dragged
     * @param dropRecord - Record on which user want to drop the dragged record
     * @param index - Drop Record Index- Used for dropping before,after or inside
     * @return {boolean}
     */
    isAllowedDrop(dragRecord, dropRecord, index = -1) {
      try {
        //reset dragged record for context menu
        this.draggedRecord = dragRecord;
        this.droppedRecord = dropRecord;
        const dragRecordTypeId = dragRecord?.recordTypeId ?? -1;
        const dropRecordTypeId = dropRecord?.recordTypeId ?? -1;

        //Condition to not drop on same dragged record
        if (dragRecord?.id === dropRecord?.id) {
          return false;
        }

        if (dragRecord?.isLink ?? false) {
          return this.canMoveShortcut;
        }

        // Verify if Dragged Record is Backup Material for sequencing/re-ordering
        if (isBackupMaterial(dragRecord)) {
          return this.canMoveBackupMaterial(dragRecord, dropRecord, index);
        }

        // Verify if Dragged Record is Meeting Child for sequencing/re-ordering
        if (isAncestorMeeting(dragRecord)) {
          return this.canMoveMeetingContent(dragRecord, dropRecord, index);
        }

        switch (dragRecordTypeId) {
          case recordType.RECORD:
            return this.canMoveRecord(dropRecordTypeId);
          case recordType.FILE:
            return this.canMoveFile(dropRecordTypeId);
          case recordType.FOLDER:
            return this.canMoveFolder(dropRecordTypeId);
          case recordType.DOCUMENT:
            return this.canMoveDocument(dropRecordTypeId);
          case recordType.MEETING:
            return this.canMoveMeeting(dropRecordTypeId);
          case recordType.ITEM:
            return this.canMoveItem(dropRecordTypeId);
          default:
            return false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Verifies if record can be copied to another record
     * @param sourceRecord - Record that is copied
     * @param destinationRecord - Record on which user want to copy
     * @param {{Boolean}} isPasteShortcut
     * @return {boolean}
     */
    isAllowedCopy(sourceRecord, destinationRecord, isPasteShortcut = false) {
      try {
        const sourceRecordTypeId = sourceRecord?.recordTypeId ?? -1;
        const destinationRecordTypeId = destinationRecord?.recordTypeId ?? -1;

        //Condition to not copy on same record
        if (sourceRecord?.id === destinationRecord?.id) {
          return false;
        }
        switch (sourceRecordTypeId) {
          case recordType.RECORD:
            return this.canCopyRecord(destinationRecordTypeId);
          case recordType.FILE:
            return this.canCopyFile(destinationRecordTypeId);
          case recordType.FOLDER:
            return isPasteShortcut
              ? this.canCopyFolder(destinationRecordTypeId)
              : false;
          case recordType.DOCUMENT:
            return this.canCopyDocument(destinationRecordTypeId);
          case recordType.ITEM:
            return this.canCopyItem(destinationRecordTypeId);
          default:
            return false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Reset data to default
     */
    resetVariables() {
      this.draggedRecord = undefined;
      this.destinationRecord = undefined;
      this.droppedRecord = undefined;
      this.dropIndex = null;
      this.dropPosition = null;
    },

    /**
     * Determine record types where record can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveRecord(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.FOLDER ||
        dropRecordTypeId === recordType.ITEM
      );
    },

    /**
     * Determine record types where file can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveFile(dropRecordTypeId) {
      if (isCompoundFile(this.draggedRecord)) {
        return this.allowMoveCompoundFile(dropRecordTypeId);
      }
      return (
        dropRecordTypeId === recordType.FOLDER ||
        this.canMoveFileToDocument(dropRecordTypeId) ||
        dropRecordTypeId === recordType.MEETING ||
        dropRecordTypeId === recordType.ITEM
      );
    },

    /**
     * Determine record types where folder can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveFolder(dropRecordTypeId) {
      return dropRecordTypeId === recordType.FOLDER;
    },

    /**
     * Determine record types where Document can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveDocument(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.FOLDER ||
        dropRecordTypeId === recordType.MEETING ||
        dropRecordTypeId === recordType.ITEM
      );
    },

    /**
     * Determine record types where Meeting can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveMeeting(dropRecordTypeId) {
      return dropRecordTypeId === recordType.FOLDER;
    },

    /**
     * Determine record types where Item can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canMoveItem(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.ITEM ||
        dropRecordTypeId === recordType.SECTION
      );
    },

    /**
     * Determine record types where Agenda Item BackupMaterial can be moved
     * @param dragRecord
     * @param dropRecord
     * @param index
     * @return {boolean}
     */
    canMoveBackupMaterial(dragRecord, dropRecord, index) {
      // if index === -1, means its not drag and drop, rather a context Menu Cut/Paste
      // No need for handling drag/drop events
      if (index === -1) {
        return (
          dropRecord?.recordTypeId === recordType?.FOLDER ||
          dropRecord?.recordTypeId === recordType?.DOCUMENT
        );
      }
      const boundingRect = event.target.getBoundingClientRect();
      const offset = event.clientY - boundingRect.top;

      // Check if dropRecord.recordTypeId is not equal to recordType?.Document
      const isNotDocument = dropRecord?.recordTypeId !== recordType?.DOCUMENT;

      // Check if dragRecordTypeId is equal to recordType?.Record
      const isRecordTypeFile = dragRecord?.recordTypeId === recordType?.FILE;

      // Adjust logic to determine hover position
      if (offset < boundingRect.height * 0.1) {
        this.dropPosition = treeInsertOptions.beforeSibling;
      } else {
        this.dropPosition = isNotDocument
          ? treeInsertOptions.afterSibling
          : !isRecordTypeFile // makes sure only record type File is inserted into Compound Documents
          ? treeInsertOptions.afterSibling
          : treeInsertOptions.lastChild;
      }

      this.dropIndex = index;

      const dropRecordParentTypeId = dropRecord?.ancestor?.recordType?.id ?? -1;

      return dropRecordParentTypeId === recordType?.ITEM;
    },

    /**
     * Determine record types where Meeting Content (section,packets,drafts,documents) can be moved
     * @param dragRecord
     * @param dropRecord
     * @param index
     * @return {boolean}
     */
    canMoveMeetingContent(dragRecord, dropRecord, index) {
      // if index === -1, means its not drag and drop, rather a context Menu Cut/Paste
      // No need for handling drag/drop events
      if (index === -1) {
        return (
          dropRecord?.recordTypeId === recordType?.FOLDER ||
          dropRecord?.recordTypeId === recordType?.DOCUMENT
        );
      }

      const boundingRect = event.target.getBoundingClientRect();
      const offset = event.clientY - boundingRect.top;

      // Adjust logic to determine hover position
      if (offset < boundingRect.height * 0.1) {
        this.dropPosition = treeInsertOptions.beforeSibling;
      } else {
        this.dropPosition = treeInsertOptions.afterSibling;
      }

      this.dropIndex = index;

      const isDragSection = dragRecord?.recordTypeId === recordType?.SECTION;
      const isDropSection = dropRecord?.recordTypeId === recordType?.SECTION;

      return (
        (isDragSection && isDropSection) || (!isDragSection && !isDropSection)
      );
    },

    /**
     * Determine record types where record can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canCopyRecord(dropRecordTypeId) {
      return dropRecordTypeId === recordType.FOLDER;
    },

    /**
     * Determine record types where file can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canCopyFile(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.FOLDER ||
        dropRecordTypeId === recordType.DOCUMENT ||
        dropRecordTypeId === recordType.MEETING ||
        dropRecordTypeId === recordType.ITEM
      );
    },

    /**
     * Determine record types where folder can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canCopyFolder(dropRecordTypeId) {
      return dropRecordTypeId === recordType.FOLDER;
    },

    /**
     * Determine record types where Document can be moved
     * @param {Number|number} dropRecordTypeId
     */
    canCopyDocument(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.FOLDER ||
        dropRecordTypeId === recordType.ITEM
      );
    },

    /**
     * Determine record types where Item can be copied
     * @param {Number|number} dropRecordTypeId
     */
    canCopyItem(dropRecordTypeId) {
      return (
        dropRecordTypeId === recordType.ITEM ||
        dropRecordTypeId === recordType.SECTION
      );
    },

    /**
     * Verify if File Can be Moved to Compound Document
     * @param dropRecordTypeId
     * @return {boolean}
     */
    canMoveFileToDocument(dropRecordTypeId) {
      return dropRecordTypeId === recordType.DOCUMENT && !this.isMultiPageTif();
    },

    /**
     * Verify if File is a multi page Tif
     * @return {boolean}
     */
    isMultiPageTif() {
      return (
        (this.draggedRecord?.extension ?? "")
          .toLowerCase()
          .includes(extensionName.tif) &&
        (this.draggedRecord?.pageCount ?? 0) > 1
      );
    },

    /**
     * Verify if local file drop to a record is allowed
     * Note: Core implementation, currently limited to file and record under a folder and agenda item only
     * @param record
     * @return {boolean}
     */
    isAllowedLocalFileDrop(record) {
      const recordTypeId = record?.recordTypeId ?? -1;
      const ancestorTypeId = record?.ancestor?.recordType?.id ?? -1;
      return (
        recordTypeId === recordType.ITEM ||
        ((recordTypeId === recordType.RECORD ||
          recordTypeId === recordType.FILE ||
          recordTypeId === recordType.FOLDER) &&
          ancestorTypeId === recordType.FOLDER)
      );
    },

    /**
     * Verify if dropped item is File
     * @param item
     * @return {{type}|*|undefined}
     */
    isFile(item) {
      if (!item.type && item.size % 4096 === 0) {
        return undefined;
      }
      return item;
    },

    /**
     * Determine if Shortcut is Creatable
     * @param sourceRecord
     * @param destinationRecord
     * @return {Promise<*>}
     */
    async isShortcutCreatable(sourceRecord, destinationRecord) {
      try {
        const payload = {
          id: sourceRecord?.id ?? -1,
          parentId: destinationRecord?.id ?? -1
        };
        const result = await canCreateShortcut(payload);
        return result?.data;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Determine if compound files can be moved within
     * @param dropRecordTypeId
     * @return {boolean|boolean}
     */
    allowMoveCompoundFile(dropRecordTypeId) {
      return Number(this.draggedRecord.name) ===
        Number(this.droppedRecord.name) + 1
        ? false
        : dropRecordTypeId === recordType.FILE;
    }
  },

  watch: {
    async droppedRecord() {
      if (this.droppedRecord?.recordTypeId !== recordType.FOLDER) return false; //Verify if dropped Record is Folder

      //Determine if Dragged record shortcut is already present in selected Record
      //Determine if Dragged Original Record is already present in selected Record
      // Determine if shortcut is Creatable
      // Will be used to disable drop Command
      this.canMoveShortcut = await this.isShortcutCreatable(
        this.draggedRecord,
        this.droppedRecord
      );
    }
  }
};
