import { render, staticRenderFns } from "./RecordList.vue?vue&type=template&id=74843a1b&scoped=true"
import script from "./RecordList.vue?vue&type=script&lang=js"
export * from "./RecordList.vue?vue&type=script&lang=js"
import style0 from "./RecordList.vue?vue&type=style&index=0&id=74843a1b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74843a1b",
  null
  
)

export default component.exports